import Hamburger from '@components/bits/Hamburger';
import React, { FunctionComponent } from 'react';

import styles from './index.module.scss';
type Props = {
  toggleMenu;
  color: string;
};
const HamburgerMenu: FunctionComponent<Props> = ({ toggleMenu, color = 'white' }) => {
  return (
    <button className={`hamburger ${styles.root}`} onClick={toggleMenu}>
      <Hamburger color={color} />
    </button>
  );
};

export default React.memo(HamburgerMenu);
