import React from 'react';

const SimpleArrow = ({ direction = 'right', color = 'black', rotate = 0 }): JSX.Element => {
  const style = {
    transform: `scale(${direction === 'right' ? 1 : -1}) rotate(${rotate}deg)`,
  };

  return (
    <svg
      style={style}
      width="6"
      height="11"
      viewBox="0 0 6 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M0.927246 1L5.09215 5.73684L0.927246 10" stroke={color} strokeWidth="1.25" />
    </svg>
  );
};

export default React.memo(SimpleArrow);
