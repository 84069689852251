import Link from 'next/link';
import React, { FunctionComponent } from 'react';

import styles from './index.module.scss';

type Props = {
  copyright: string;
  personvern;
  apenhetsloven;
};

const FooterBottom: FunctionComponent<Props> = ({ copyright, personvern, apenhetsloven }) => {
  const url = personvern?.route?.director?.slug?.current;
  const apenhetUrl = apenhetsloven?.route?.director?.slug?.current;

  return (
    <div className={styles.bottom}>
      <div className={styles.inner}>
        <p>{copyright}</p>
        <hr style={{ width: '52px' }} />
        <Link href={`/${url}`}>{personvern?.description}</Link>
        <hr style={{ width: '52px' }} />
        <Link href={`/${apenhetUrl}`}>{apenhetsloven?.description || ''}</Link>
      </div>
    </div>
  );
};

export default React.memo(FooterBottom);
