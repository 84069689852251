import SimpleBlockContent from '@components/bits/SimpleBlockContent';
import SquareButton from '@components/bits/SquareButton';
import React, { FunctionComponent, useState } from 'react';

import styles from './index.module.scss';
import InputComp from './InputComp';
type Props = {
  cookieData;
  updateCookies;
  closeButton;
  settingsButtonText;
  desc;
  settings;
};

const Cookies: FunctionComponent<Props> = ({
  cookieData,
  updateCookies,
  closeButton,
  settingsButtonText,
  desc,
  settings,
}) => {
  const [settingsOpen, setSettingsOpen] = useState(false);

  const closeCookiePopup = (): void => {
    const newD = { ...cookieData };
    newD.seenCookie = true;
    updateCookies(newD);
  };

  return (
    <div className={styles.root}>
      <SimpleBlockContent data={desc} />

      <div className={`${styles['settings']} ${settingsOpen ? styles['active'] : ''}`}>
        <InputComp {...settings.function} cookieData={cookieData} updateCookies={updateCookies} />
        <InputComp
          {...settings.performance}
          cookieData={cookieData}
          updateCookies={updateCookies}
        />
        <InputComp {...settings.some} cookieData={cookieData} updateCookies={updateCookies} />
      </div>

      <div className={styles['buttons']}>
        <SquareButton text={closeButton} onClick={closeCookiePopup} />
        <button onClick={() => setSettingsOpen(!settingsOpen)}>{settingsButtonText}</button>
      </div>
    </div>
  );
};

export default Cookies;
