import { useRouter } from 'next/router';
import React from 'react';

import styles from './index.module.scss';

const LocalesTriggers = (): JSX.Element => {
  const router = useRouter();

  const setCookieAndGotoPage = (lang: string, path: string): void => {
    if (lang !== router.locale) {
      document.cookie = `NEXT_LOCALE=${lang}`;
      router.push(path, path, { locale: lang });
    }
  };

  return (
    <div className={styles.root}>
      {router?.locales?.map((val, key) => {
        const active = val === router.locale ? 'active' : '';

        return (
          <button
            className={styles[active]}
            key={key}
            onClick={() => setCookieAndGotoPage(val, router.asPath)}>
            {val}
          </button>
        );
      })}
    </div>
  );
};

export default React.memo(LocalesTriggers);
