import SimpleBlockContent from '@components/bits/SimpleBlockContent';
import { triggerEmailEvent, triggerPhoneEvent } from '@utils/gtag';
import iub from '@utils/ImageUrlBuilder';
import Link from 'next/link';
import React, { FunctionComponent } from 'react';

import styles from './index.module.scss';

type Props = {
  logo?;
  address?: string;
  email?: string;
  phone?: string;
  elements?;
};

const FooterColumn: FunctionComponent<Props> = ({ logo, address, email, phone, elements }) => {
  return (
    <div className={styles.footerColumn}>
      <>{logo && <img alt={logo?.title} src={iub(logo, false).width(100).url()} />}</>
      {address && <SimpleBlockContent data={address} />}
      <>
        {email && <a href={`mailto:${email}`} onClick={() => triggerEmailEvent(`mailto:${email}`)}>
          {email}
        </a>}
        {phone && <a href={`tel:${phone}`} onClick={() => triggerPhoneEvent(`mailto:${phone}`)}>
          {phone}
        </a>}
      </>
      {elements?.map((val, key) => {
        const { _type } = val;

        if (_type == 'route') {
          const url = val?.director?.slug?.current;
          return (
            <Link key={key} href={`/${url}`}>
              {val.director.title}
            </Link>
          );
        }
        if (_type == 'link') {
          return (
            <Link href={`/${val.href}`} key={key}>
              {val.href || 'no slug'}
            </Link>
          );
        }
        return null;
      })}
    </div>
  );
};

export default React.memo(FooterColumn);
