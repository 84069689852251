import SimpleArrow from '@components/bits/icons/SimpleArrow';
import SimpleBlockContent from '@components/bits/SimpleBlockContent';
import React, { FunctionComponent, useState } from 'react';

import styles from './index.module.scss';
type Props = {
  cookieData;
  title;
  desc;
  updateCookies;
  deseletable;
};

const InputComp: FunctionComponent<Props> = ({
  cookieData,
  title,
  desc,
  updateCookies,
  deseletable,
}) => {
  const isActive = cookieData && cookieData[title];
  const [open, setOpen] = useState(false);

  const toggleActive = (): void => {
    
    if (deseletable === true) {
      const newD = { ...cookieData };
      newD[title] = newD[title] ? false : true;

      updateCookies(newD);
    }
  };
  const toggleOpen = ():void => {
    setOpen(!open);
  };

  return (
    <div className={styles.inputComp}>
      <button
        className={`${styles['left']} ${isActive ? styles['active'] : ''}`}
        onClick={toggleActive}></button>
      <button className={styles['right']} onClick={toggleOpen}>
        <h5>
          {title} <SimpleArrow rotate={open ? -90 : 90} />
        </h5>
        {open && <SimpleBlockContent data={desc} />}
      </button>
    </div>
  );
};

export default InputComp;
