import Link from 'next/link';
import React, { FunctionComponent } from 'react';

import styles from './index.module.scss';

type Props = {
  director;
  routes;
};

const RouteList: FunctionComponent<Props> = ({ director, routes }) => {
  const url = director.slug.current !== '/' ? director.slug.current : '';

  return (
    <span className={`${styles.root}`}>
      <span className={`label ${styles['_label']}`}>
        <Link href={`/${url}`}>{director.title}</Link>
      </span>

      {
        routes && <span className={styles['_routes']}>
        { routes?.map((val, key) => {
          const { content } = val;

          if (!val.brand || (val.brand && val.active === true)) {
            if (content?.brandInfo) {
              const data = content.brandInfo;
              return (
                <Link key={key} as={`/${data.slug.current}`} href={`/${data.slug.current}`}>
                  <div>
                    {data.name} <span>00{key + 1}</span>
                  </div>
                </Link>
              );
            }
          }
          return null;
        })}
      </span>
      }

      
    </span>
  );
};

export default React.memo(RouteList);
