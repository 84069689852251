import React from 'react';

import styles from './index.module.scss';

const Hamburger = ({ color = 'white' }): JSX.Element => {
  return (
    <svg
      className={styles.root}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="10"
      fill="none"
      viewBox="0 0 14 10">
      <path stroke={color} strokeLinecap="round" strokeLinejoin="round" d="M5 9h8M1 5h12M1 1h12" />
    </svg>
  );
};

export default React.memo(Hamburger);
