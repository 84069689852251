import PropTypes from 'prop-types';
import React from 'react';

type Props = {
  url?: string;
};

const Logo = ({ url }: Props): JSX.Element => {
  const styles = {
    padding: '10px',
    width: '100px',
    cursor: 'pointer',
  };

  return <img style={styles} src={url} alt={'logo'} />;
};

Logo.propTypes = {
  color: PropTypes.string,
};

Logo.defaultProps = {
  color: 'white',
};

export default React.memo(Logo);
