import {
  createImageUrlBuilder,
  createPortableTextComponent,
  createPreviewSubscriptionHook,
} from 'next-sanity';
import { ImageUrlBuilder } from 'next-sanity-image';

import { sanityConfig } from './config';

const imageBuilder = createImageUrlBuilder(sanityConfig);

const urlForImage = (source: string): ImageUrlBuilder => {
  return imageBuilder.image(source).auto('format').fit('max');
};

const PortableText = createPortableTextComponent({
  ...sanityConfig,
  // Serializers passed to @sanity/block-content-to-react
  // (https://github.com/sanity-io/block-content-to-react)
  serializers: {},
});

const usePreviewSubscription = createPreviewSubscriptionHook(sanityConfig);

export { urlForImage, PortableText, usePreviewSubscription };
//
