/**
 * // useWindowDimension.ts
 * * This hook returns the viewport/window height and width
 */

import { useEffect, useState } from 'react';

type WindowDimentions = {
  width: number | undefined;
  height: number | undefined;
};

const useWindowDimensions = (): WindowDimentions => {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimentions>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize(): void {

      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

      setWindowDimensions({
        width: isSafari ? document.documentElement.clientWidth || window.innerWidth || document.body.clientWidth : window.innerWidth || document.body.clientWidth,
        height: isSafari ? document.documentElement.clientHeight || window.innerHeight || document.body.clientHeight : window.innerHeight || document.body.clientHeight,
      });
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return (): void => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowDimensions;
};

export default useWindowDimensions;
