import FooterBottom from '@components/layout/Footer/FooterBottom';
import FooterColumn from '@components/layout/Footer/FooterColumn';
import React, { FunctionComponent } from 'react';

import styles from './index.module.scss';

type Props = {
  newsletter: { description: string; button: string; placeholder: string };
  backgroundColor: string;
  footerNavigationC1: { logo };
  footerNavigationC2: { address: string };
  footerNavigationC3: { email: string; phone: string };
  footerNavigationC4: { elements };
  copyright: { description: string };
  personvern: { personvern };
  apenhetsloven: { apenhetsloven };
};

const Footer: FunctionComponent<Props> = ({
  // newsletter,
  // backgroundColor,
  footerNavigationC1,
  footerNavigationC2,
  footerNavigationC3,
  footerNavigationC4,
  copyright,
  personvern,
  apenhetsloven,
}) => {
  return (
    <footer className={styles.root}>
      <div className={styles['wrapper-outer']}>
        <div className={styles['wrapper-inner']}>
          {/* <Newslettes props={newsletter} backgroundColor={backgroundColor} /> */}
          <div className={styles['_top']}>
            <FooterColumn logo={footerNavigationC1.logo} />
            <FooterColumn address={footerNavigationC2.address} />
            <FooterColumn email={footerNavigationC3.email} phone={footerNavigationC3.phone} />
            <FooterColumn elements={footerNavigationC4.elements} />
          </div>
          <FooterBottom
            copyright={copyright.description}
            personvern={personvern}
            apenhetsloven={apenhetsloven}
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
