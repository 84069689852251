export const NEXT_PUBLIC_GA_4_ID = process.env.NEXT_PUBLIC_GA_4_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  window.gtag('config', NEXT_PUBLIC_GA_4_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

export const triggerEmailEvent = (email) => {
  const gaEvent = {
    action: 'Email',
    category: 'interactions',
    label: email,
    value: '',
  };
  event(gaEvent);
};

export const triggerPhoneEvent = (phone) => {
  const gaEvent = {
    action: 'Telefon',
    category: 'interactions',
    label: phone,
    value: '',
  };
  event(gaEvent);
};
