import imageUrlBuilder from '@sanity/image-url';

import sanityClient from '../client.js';

const builder = imageUrlBuilder(sanityClient);

export default function urlFor(source, forWebGL = false, dpr = 2) {
  return forWebGL
    ? builder.image(source).format('webp').fit('max').dpr(dpr).quality(90)
    : builder.image(source).auto('format').fit('max').dpr(dpr).quality(90);
}
