import React, { FunctionComponent, memo, useState } from 'react';

import styles from './index.module.scss';

type Props = {
  cta?;
  color?: string;
  outline?: boolean;
  overrideText?: boolean;
  text?: string;
  onClick?: () => void;
  className?: string;
};

const SquareButton: FunctionComponent<Props> = ({
  // cta,
  color = 'black',
  text = null,
  onClick = null,
  // overrideText = false,
  outline = false,
  className,
}) => {
  const [reverseColors, setReverseColors] = useState(false);

  let colorStyle;

  if (!reverseColors) {
    colorStyle = {
      backgroundColor: color,
      color: color !== 'white' ? 'white' : 'black',
      border: '0.5px solid ' + color,
    };
  } else {
    colorStyle = {
      backgroundColor: color !== 'white' ? 'white' : 'black',
      color: color,
      border: outline ? '0.5px solid ' + color : 'null',
    };
  }

  return (
    <button
      style={colorStyle}
      className={`${styles.root} ${className}`}
      onClick={onClick || null}
      onMouseOver={() => setReverseColors(true)}
      onMouseOut={() => setReverseColors(false)}
      onFocus={() => setReverseColors(true)}
      onBlur={() => setReverseColors(false)}>
      {/* {cta ? <CtaLink cta={cta} text={text} overrideText={overrideText} /> : text} */}
      {/* Cretae a new CtaLink for this */}
      { text }
    </button>
  );
};

export default memo(SquareButton);
